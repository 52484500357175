<template>
  <div>
    <h1 class="mb-10">{{ title }}</h1>

    <div class="filters">
      <InputGroup label="Start Date" name="start_date">
        <DatePicker v-model="filters.start_date" :append-icon="false" :presets="false" @input="onStartDateChange" />
      </InputGroup>
      <InputGroup label="End Date" name="end_date">
        <DatePicker v-model="filters.end_date" :append-icon="false" :presets="false" @input="onEndDateChange" />
      </InputGroup>
      <InputGroup label="Country" name="country_id">
        <Select v-model="filters.country_id" endpoint="analytics/trade/filters/countries" prepend-option="All" />
      </InputGroup>
      <InputGroup label="Host agency" name="host_agency_id">
        <Select
          v-model="filters.host_agency_id"
          endpoint="analytics/trade/filters/host-agencies"
          prepend-option="All"
        />
      </InputGroup>
      <InputGroup label="Consortium" name="consortium_id">
        <Select v-model="filters.consortium_id" endpoint="analytics/trade/filters/consortiums" prepend-option="All" />
      </InputGroup>
      <InputGroup label="Language" name="language_id">
        <Select v-model="filters.language_id" endpoint="analytics/trade/filters/languages" prepend-option="All" />
      </InputGroup>
    </div>

    <FormErrors v-if="advisorTableErrors" :errors="advisorTableErrors.errors" :message="advisorTableErrors.message" />

    <DataTable
      :csv-export="true"
      :filters="filters"
      :new-format="true"
      :perPage="10"
      :sort="{ consumer_views: 'desc' }"
      endpoint="analytics/trade/top-performing-advisors"
      title="Top Performing Advisors with Experiences"
      @errors="errors => (advisorTableErrors = errors)"
      @fetching="advisorTableErrors = false"
    >
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn sortable="first_name" title="Advisor">
            <router-link
              :class="{ 'text-supplement': !row.full_name }"
              :to="{ name: 'inspires-user-analytics', params: { id: row.id } }"
              class="flex items-center space-x-4"
            >
              <img v-if="row.avatar" :src="row.avatar.thumb_url" class="h-10 w-10 rounded-full" />
              <span
                v-else
                class="grid h-10 w-10 place-items-center rounded-full bg-gray-200 text-xs font-medium text-supplement"
                >{{ row.initials || "?" }}</span
              >
              <span>{{ row.full_name || "-" }}</span>
            </router-link>
          </DataTableColumn>
          <DataTableColumn sortable="acceptances" title="Acceptances" width="max">
            {{ row.acceptances | number }}
          </DataTableColumn>
          <DataTableColumn sortable="consumer_views" title="Views" width="max">
            {{ row.consumer_views | number }}
          </DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>

    <FormErrors
      v-if="collectionTableErrors"
      :errors="collectionTableErrors.errors"
      :message="collectionTableErrors.message"
    />

    <DataTable
      :csv-export="true"
      :filters="filters"
      :new-format="true"
      :perPage="10"
      :sort="{ consumer_views: 'desc' }"
      endpoint="analytics/trade/top-performing-collections"
      title="Top Performing Collections"
      @errors="errors => (collectionTableErrors = errors)"
      @fetching="collectionTableErrors = false"
    >
      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn sortable="name" title="Collection">
            <router-link :to="{ name: 'extended-collection-analytics', params: { id: row.id } }"
              >{{ row.name }}
            </router-link>
            <div v-if="row.folder || row.code" class="mt-1 space-x-6 text-supplement">
              <span v-if="row.folder">{{ row.folder }}</span>
              <span v-if="row.code">{{ row.code.join(", ") }}</span>
            </div>
          </DataTableColumn>
          <!-- <DataTableColumn sortable="status" title="Status" width="max">
            <small class="text-muted">{{ row.status }}</small>
          </DataTableColumn> -->
          <DataTableColumn sortable="acceptances" title="Acceptances" width="max">
            {{ row.acceptances | number }}
          </DataTableColumn>
          <DataTableColumn sortable="consumer_views" title="Views" width="max">
            {{ row.consumer_views | number }}
          </DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { sub as dateSub } from "date-fns"
import { isEmpty } from "lodash-es"
import FormErrors from "../../components/Form/FormErrors.vue"

function formatDate(date) {
  let month = date.getMonth() + 1
  let day = date.getDate()

  return [date.getFullYear(), (month > 9 ? "" : "0") + month, (day > 9 ? "" : "0") + day].join("-")
}

export default {
  components: {
    FormErrors
  },
  watch: {
    $route: "setup"
  },
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-trade-analytics" } }
  },
  data: () => ({
    title: "Trade Analytics",
    filters: {
      start_date: "2022-10-01",
      end_date: formatDate(dateSub(new Date(), { days: 1 })),
      collection_id: null,
      folder_id: null,
      country_id: null,
      host_agency_id: null,
      consortium_id: null,
      language_id: null
    },
    advisorTableErrors: null,
    collectionTableErrors: null
  }),
  beforeMount() {
    this.setup()
  },
  methods: {
    async setup() {
      let { start_date, end_date } = this.$route.query
      let query = {}

      if (start_date) {
        this.filters.start_date = start_date
      } else {
        query.start_date = this.filters.start_date
      }

      if (end_date) {
        this.filters.end_date = end_date
      } else {
        query.end_date = this.filters.end_date
      }

      this.dateRange = this.currentDateRange()

      if (!isEmpty(query)) {
        this.updateUri(query)
      }
    },
    currentDateRange() {
      return this.filters.start_date === "2022-10-01" &&
        this.filters.end_date === formatDate(dateSub(new Date(), { days: 1 }))
        ? "all-time"
        : "custom"
    },
    onStartDateChange(date) {
      this.dateRange = this.currentDateRange()
      this.updateUri({ start_date: date })
    },
    onEndDateChange(date) {
      this.dateRange = this.currentDateRange()
      this.updateUri({ end_date: date })
    },
    updateUri(query) {
      this.$router.replace({
        name: "trade-analytics",
        query: Object.assign({}, this.$route.query, query)
      })
    },
    changeDateRange(range) {
      if (range === this.dateRange) {
        return false
      }

      const start_date = range === "all-time" ? "2022-10-01" : formatDate(dateSub(new Date(), { months: 1, days: 1 }))
      const end_date = formatDate(dateSub(new Date(), { days: 1 }))

      this.dateRange = range
      this.filters.start_date = start_date
      this.filters.end_date = end_date

      this.updateUri({ start_date, end_date })
    }
  }
}
</script>

<style>
.page-trade-analytics {
  .section-title {
    @apply my-6 border-b-2 pb-3;
    @apply md:my-8;
    @apply lg:mt-12;
  }

  .card + .section-title {
    @apply lg:mt-20;
  }

  .filters {
    @apply md:-mx-3 md:mb-6 md:-mt-6 md:flex md:flex-wrap;
    @apply lg:mb-12;

    .form-inputgroup {
      @apply md:mx-3 md:mt-6 md:mb-0;
    }
  }

  .filters__filter-by .form-autocomplete-results .icon {
    @apply mr-3 text-supplement;
  }
}
</style>
