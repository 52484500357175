<template>
  <Alert class="form-errors" type="danger" :title="actualMessage">
    <ul v-if="actualErrors">
      <li v-for="error in actualErrors" :key="error">{{ error }}</li>
    </ul>
  </Alert>
</template>

<script>
export default {
  name: "form-errors",

  props: {
    message: {
      type: String,
      default: undefined
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    actualMessage() {
      if (this.actualErrors) {
        return "Please correct the following errors:"
      }

      if (this.message) {
        return this.message
      }

      return "An error occured loading the data."
    },
    actualErrors() {
      if (Object.entries(this.errors).length === 0) {
        return false
      }

      // return this.errors.map()
      return Object.values(this.errors).map(v => (Array.isArray(v) ? v.join(", ") : v))
    }
  }
}
</script>

<style lang="postcss">
.alert.form-errors {
  & + .card {
    @apply mt-10;
  }

  .card + & {
    @apply mt-10;
  }
  ul {
    @apply list-inside list-disc;
  }
}
</style>
